// .navbar-brand-box,
// .vertical-menu,
// body[data-sidebar="dark"].vertical-collpsed
//   .vertical-menu
//   .sidebar-menu
//   > ul
//   > li:hover
//   > a,
// body[data-sidebar="dark"].vertical-collpsed
//   .vertical-menu
//   .sidebar-menu
//   > ul
//   ul {
//   background: #000000;
// }
// !//important
.rotateClockWise90 {
  transform: rotate(90deg);
}

.profileMenuContainer {
  align-items: center;

  .MuiChip-labelSmall {
    padding: 20px;
    font-weight: bold;
  }
}

.sidebar-menu {
  li a {
    color: black;

    span {
      font-size: 16px !important;
    }
  }
}
