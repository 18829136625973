//
// _menu.scss
//
:root {
  --sidebar-text-color: #f8f9fa;
}
.metismenu {
  margin: 0;
  font-family: "Vastago Grotesk", "sans-serif";

  li {
    display: block;
    width: 100%;
  }

  .mm-collapse {
    display: none;

    &:not(.mm-show) {
      display: none;
    }

    &.mm-show {
      display: block;
    }
  }

  .mm-collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition-timing-function: ease;
    transition-duration: 0.35s;
    transition-property: height, visibility;
  }
}

.menu-icon-style {
  margin-right: 12px;
  color: #6a7187;
  font-size: 20px;
}
.main-menu-title {
  color: #6a7187;
  font-size: 22px;
}
.other-items-title {
  color: #6a7187;
  font-size: 22px;
}
.vertical-menu {
  width: $sidebar-width;
  z-index: 1001;
  background: $sidebar-bg;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  // top: $header-height;
  top: 0;
  // overflow-y: auto;
  // overflow-x: hidden;
  max-height: 100vh;
  box-shadow: $box-shadow;
}

/* Hide Sidebar on Small Screens */
@media (max-width: 991px) {
  .vertical-menu {
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .vertical-menu.active {
    transform: translateX(0);
  }

  /* Adjust main content padding to account for header */
  .main-content {
    padding-top: $header-height;
  }
}

/* Always Show Sidebar on Large Screens */
@media (min-width: 992px) {
  .vertical-menu {
    transform: translateX(0);
  }
  .profile-dropdown-menu {
    /* Styles for larger screens */
    // width: 200px;
  }
  /* Hide Header on Large Screens */
  #page-topbar {
    // display: none;
  }

  .main-content {
    margin-left: $sidebar-width;
    padding-top: 0;
  }
}

.main-content {
  margin-left: $sidebar-width;
  overflow: hidden;

  .content {
    padding: 0 15px 10px 15px;
    // margin-top: $header-height;
  }
}
.vertical-menu {
  .simplebar-mask,
  .simplebar-content-wrapper {
    overflow: visible;
  }
  .profile-dropdown-items {
    padding-left: 20px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    &.active,
    &:hover {
      background-color: #f8f9fa;
    }
  }
  .sidebar-menu {
    .sidebar-logo {
      padding: 1rem;
      a {
        background-color: transparent !important;
      }

      .logo {
        display: block;
        text-align: center;

        .logo-img {
          max-width: 90%;
          max-height: 70px;
          object-fit: contain;
        }
        .logo-sm,
        .logo-lg {
          display: block;
        }

        .logo-sm img {
          height: 40px;
        }

        .logo-lg img {
          height: 50px;
        }
      }
    }

    .sidebar-toggle-btn {
      position: absolute;
      top: 20px;
      right: 0px;
      transform: translateX(50%);
      z-index: 10;

      .toggle-btn {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: #ffffff;
        border: 1px solid #ddd;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #f0f0f0;
        }

        i {
          font-size: 14px;
          color: #333;
        }
      }
    }
  }
}
.sidebar-menu {
  .main-menu-title {
    font-size: 12px;
    padding-left: 0.7rem;
    margin-bottom: 0.6rem !important;

    &:before {
      content: "Main Menu";
    }
  }
  .other-items-title {
    color: #6a7187;
    font-size: 12px;
    padding-left: 0.7rem;
    margin-bottom: 0.6rem !important;
    margin-top: 0.5rem !important;
    &:before {
      content: "Other Items";
    }
  }
  .mm-active {
    > .has-arrow {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }
  .ellipsis-text {
    display: inline-block;
    max-width: 150px;
    /* Adjust the width as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--sidebar-text-color);
  }

  .has-arrow {
    display: flex;
    align-items: center;

    &:after {
      content: "\F0140";
      font-family: "Material Design Icons";
      margin-left: auto;
      transition: transform 0.2s;
      font-size: 1rem;
    }
  }
  .has-profile-arrow {
    display: flex;
    align-items: center;
    color: var(--sidebar-text-color);

    &:after {
      content: "\F0142";
      font-family: "Material Design Icons";
      margin-left: auto;
      transition: transform 0.2s;
      font-size: 1rem;
    }
  }

  i svg {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    stroke: var(--sidebar-text-color);
  }
  i .svg-path {
    stroke: #000;
  }

  ul {
    li {
      a {
        display: block;
        padding: 0.625rem 1.5rem;
        // color: $sidebar-menu-item-color;
        position: relative;
        font-size: 13px;
        transition: all 0.4s;
        font-weight: 500;
        color: var(--sidebar-text-color);

        i {
          display: inline-block;
          // color: red;
          min-width: 1.75rem;
          justify-content: center;
          font-size: 1.25rem;
          line-height: 1.40625rem;
          // vertical-align: middle;
          // color: #000;
          transition: all 0.4s;
        }
        span {
          color: var(--sidebar-text-color);
        }

        &:hover {
          // color: $sidebar-menu-item-hover-color;
          .svg-path {
            // stroke: $sidebar-menu-item-hover-color;
          }
          i {
            // color: $sidebar-menu-item-hover-color;
          }
        }
      }

      .badge {
        margin-top: 4px;
      }

      ul.sub-menu {
        padding: 0;

        li {
          a {
            color: var(--sidebar-text-color);
            display: "none";
            // padding: 0.4rem 1.5rem 0.4rem 3.5rem;
            padding-left: 2rem;
            margin-top: 0.1rem;
            font-size: 13px;
            // color: $sidebar-menu-sub-item-color;
            // padding-left: 2rem !important;
          }

          ul.sub-menu {
            padding: 0;

            li {
              a {
                // padding: 0.4rem 1.5rem 0.4rem 4.5rem;
                padding-left: 0rem !important;
                font-size: 13.5px;
              }
            }
          }
        }
      }
    }
  }
}
span {
  color: "#000";
}
.mm-active {
  // color: $sidebar-menu-item-active-color !important;
  // i .svg-path {
  //     stroke: $sidebar-menu-item-active-color;
  //   }
  .active {
    // color: $sidebar-menu-item-active-color !important;
    // i .svg-path {
    //   stroke: $sidebar-menu-item-active-color;
    // }
    // i {
    //   color: $sidebar-menu-item-active-color !important;
    // }
    span {
      font-weight: 500;
    }
  }
  > i {
    // color: $sidebar-menu-item-active-color !important;
  }
  > span {
    font-weight: 500;
  }
}
// .mm-active i{
//   color: $sidebar-menu-item-active-color !important;
// }

.active {
  // background-color: lighten($sidebar-menu-item-active-color, 55%) !important;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  border-radius: 10px;
  padding-left: 0.5rem;
  padding-right: 0rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.vertical-menu a.mm-active {
  // background-color: lighten($sidebar-menu-item-active-color, 55%) !important;
  margin-right: 1rem !important;
  margin-left: 1rem !important;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  border-radius: 10px !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;

  ul.sub-menu {
    padding: 0;
  }
  i {
    // color: $sidebar-menu-item-active-color !important;
  }
  i .svg-path {
    // stroke: $sidebar-menu-item-active-color !important;
  }

  span {
    // color: $sidebar-menu-item-active-color !important;
  }
}

@media (max-width: 992px) {
  .vertical-menu {
    display: none;
  }

  .main-content {
    margin-left: 0 !important;
  }

  body.sidebar-enable {
    .vertical-menu {
      display: block;
    }
  }
}

// Enlarge menu
.vertical-collpsed {
  .main-content {
    margin-left: $sidebar-collapsed-width;
  }

  .navbar-brand-box {
    // width: $sidebar-collapsed-width !important;
    // flex: 1 0 auto;
  }

  .logo {
    span.logo-lg {
      display: none;
    }

    span.logo-sm {
      display: block;
    }
  }
  .vertical-menu a.mm-active {
    padding-top: 0.7rem !important;
    border-radius: 10px !important;
    padding-bottom: 0.7rem !important;
    color: #fff !important;
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;

    i {
      // color: $sidebar-menu-item-active-color !important;
      // padding:0rem !important;
    }
    span {
      padding-left: 1rem !important;
    }
  }
  .active {
    // background-color: lighten($sidebar-menu-item-active-color, 55%) !important;
    padding-top: 0.7rem !important;
    padding-bottom: 0.7rem !important;
    border-radius: 10px !important;
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
    // color: $sidebar-menu-item-active-color !important;
  }

  // Side menu
  .vertical-menu {
    // position: absolute;
    overflow: visible;
    width: $sidebar-collapsed-width !important;
    // overflow-y: scroll;
    // scrollbar-width: none;

    .simplebar-mask,
    .simplebar-content-wrapper {
      overflow-x: visible !important;
    }

    .simplebar-scrollbar {
      display: none !important;
    }

    .simplebar-offset {
      bottom: 0 !important;
    }

    // Sidebar Menu
    .sidebar-menu {
      .profile-menu {
        position: relative;
        overflow: visible;
      }

      // SIDE BAR LOGO
      .sidebar-logo {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 1rem 0.5rem;

        .logo {
          .logo-img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
          .logo-sm,
          .logo-lg {
            display: block;
          }

          .logo-sm img {
            height: 40px;
          }

          .logo-lg img {
            height: 50px;
          }
        }
      }

      .sidebar-toggle-btn {
        overflow: visible !important;
        z-index: 1100 !important;

        // position: absolute;
        // top: 20px;
        // right: -5px;
        // transform: translateX(50%); // Center the button vertically

        .toggle-btn {
          // width: 25px;
          // height: 25px;
          // border-radius: 50%;
          // background-color: #ffffff;
          // border: 1px solid #ddd;
          // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          // display: flex;
          // align-items: center;
          // justify-content: center;
          // cursor: pointer;
          // transition: background-color 0.3s;

          &:hover {
            background-color: #f0f0f0;
          }

          i {
            font-size: 14px;
            color: #333;
          }
        }
      }

      //
      .main-menu-title {
        padding-left: 1.2rem;
        color: "#6a7187";

        &:before {
          content: "Menu";
        }
      }
      .header-profile-user {
        width: 20px;
        height: 20px;
      }
      .other-items-title {
        color: #6a7187;
        padding-left: 1.2rem;
        &:before {
          content: "Other ";
        }
      }
      .menu-title,
      .badge,
      .collapse.in {
        display: none !important;
      }

      .nav.collapse {
        height: inherit !important;
      }

      .has-profile-arrow {
        &:after {
          display: none;
        }
      }
      .has-arrow {
        &:after {
          display: none;
        }
        .profile-menu {
          position: relative;
          overflow: visible; /* Allow dropdown to overflow */
        }
      }

      > ul {
        > li {
          position: relative;
          white-space: nowrap;

          > a {
            padding: 10px 15px !important;
            // min-height: 55px;
            transition: none;
            margin-left: 0.5rem;
            margin-right: 0.5rem;

            &:hover,
            &:active,
            &:focus {
              // color: $sidebar-menu-item-hover-color;
            }

            i {
              font-size: 1.45rem;
            }

            span {
              display: none;
              padding-left: 25px;
            }
          }

          &:hover {
            > a {
              position: relative;
              width: calc(190px + #{$sidebar-collapsed-width});
              // color: $sidebar-menu-item-hover-color;
              transition: none;
              background-color: #ffffff;

              i {
              }
              .svg-path {
                // stroke: $sidebar-menu-item-hover-color;
              }
              span {
                display: inline;
              }
            }

            > ul {
              display: block;
              left: $sidebar-collapsed-width;
              position: absolute;
              width: 190px;
              height: auto !important;
              box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);

              ul {
                box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
              }

              a {
                box-shadow: none;
                padding: 8px 20px;
                position: relative;
                width: 180px;
                z-index: 6;
                // color: $sidebar-menu-sub-item-color;

                &:hover {
                  // color: $sidebar-menu-item-hover-color;
                }
              }
            }
          }
        }

        ul {
          padding: 5px 0;
          z-index: 9999;
          display: none;
          background-color: $sidebar-bg;

          li {
            &:hover {
              > ul {
                display: block;
                left: 190px;
                height: auto !important;
                margin-top: -36px;
                position: absolute;
                width: 190px;
              }
            }

            > a {
              span.pull-right {
                position: absolute;
                right: 20px;
                top: 12px;
                transform: rotate(270deg);
              }
            }
          }

          li.active {
            a {
              color: $gray-100;
            }
          }
        }
      }
    }
  }
}

body[data-sidebar="dark"] {
  .vertical-menu {
    background: $sidebar-dark-bg;
    // background: #f5f5f5;
  }

  .sidebar-menu {
    .main-menu-title {
      color: $white;
      padding-left: 1rem;

      &:before {
        content: "Main Menu";
      }
    }
    ul {
      li {
        a {
          color: $sidebar-dark-menu-item-color;

          i {
            color: $sidebar-dark-menu-item-icon-color;
          }

          &:hover {
            color: $sidebar-dark-menu-item-hover-color;

            i {
              color: $sidebar-dark-menu-item-hover-color;
            }
          }
        }

        ul.sub-menu {
          li {
            a {
              color: $sidebar-dark-menu-sub-item-color;

              &:hover {
                color: $sidebar-dark-menu-item-hover-color;
              }
            }
          }
        }
      }
    }
  }
  // Enlarge menu
  &.vertical-collpsed {
    // } {
    // min-height: 1300px;
    // Side menu
    .vertical-menu {
      // Sidebar Menu
      .sidebar-menu {
        .main-menu-title {
          font-size: 12px;
          // display: block;
          padding-left: 1.2rem;

          &:before {
            content: "Menu";
          }
        }
        > ul {
          > li {
            &:hover {
              > a {
                background: lighten($sidebar-dark-bg, 2%);
                color: $sidebar-dark-menu-item-hover-color;
                i {
                  color: $sidebar-dark-menu-item-hover-color;
                }
              }

              > ul {
                a {
                  color: $sidebar-dark-menu-sub-item-color;
                  &:hover {
                    color: $sidebar-dark-menu-item-hover-color;
                  }
                }
              }
            }
          }

          ul {
            background-color: $sidebar-dark-bg;
          }
        }

        ul {
          li {
            &.mm-active .active {
              color: $sidebar-menu-item-active-color !important;
              i {
                color: $sidebar-menu-item-active-color !important;
              }
            }
          }
        }
      }
    }
  }
  .mm-active {
    color: $sidebar-dark-menu-item-active-color !important;
    > i {
      color: $sidebar-dark-menu-item-active-color !important;
    }
    .active {
      color: $sidebar-dark-menu-item-active-color !important;

      i {
        color: $sidebar-dark-menu-item-active-color !important;
      }
    }
  }

  .menu-title {
    color: $sidebar-dark-menu-item-icon-color;
  }
}

body[data-layout="horizontal"] {
  .main-content {
    margin-left: 0 !important;
  }
}

// Compact Sidebar

body[data-sidebar-size="small"] {
  .navbar-brand-box {
    width: $sidebar-width-sm;
  }
  .vertical-menu {
    width: $sidebar-width-sm;
    text-align: center;

    .has-arrow:after,
    .badge {
      display: none !important;
    }
  }
  .main-content {
    margin-left: $sidebar-width-sm;
  }
  .footer {
    left: $sidebar-width-sm;
  }

  .sidebar-menu {
    .main-menu-title {
      font-size: 10px;
      padding: 5px 10px;
      margin-bottom: 5px;
    }
    ul li {
      &.menu-title {
        background-color: lighten($sidebar-dark-bg, 2%);
      }
      a {
        i {
          display: block;
        }
      }
      ul.sub-menu {
        li a {
          padding-left: 1.5rem;
        }
      }
    }
  }
  &.vertical-collpsed {
    .main-content {
      margin-left: $sidebar-collapsed-width;
    }
    .vertical-menu {
      .sidebar-menu {
        text-align: left;
        .main-menu-title {
          font-size: 6px;
          padding: 2px 6px;
          margin-bottom: 3px;
        }
        > ul {
          > li {
            > a {
              i {
                display: inline-block;
              }
            }
          }
        }
      }
    }
    .footer {
      left: $sidebar-collapsed-width;
    }
  }
}

// colored sidebar

body[data-sidebar="colored"] {
  .vertical-menu {
    // background-color: $primary;
  }
  .navbar-brand-box {
    background-color: $primary;
    .logo-dark {
      display: none;
    }
    .logo-light {
      display: block;
    }
  }

  .mm-active {
    color: $white !important;
    > i,
    .active {
      color: $white !important;
    }
  }

  .sidebar-menu {
    .main-menu-title {
      color: $white;
    }
    ul {
      li {
        &.menu-title {
          color: rgba($white, 0.6);
        }

        a {
          color: rgba($white, 0.6);
          i {
            color: rgba($white, 0.6);
          }
          &.waves-effect {
            .waves-ripple {
              background: rgba($white, 0.1);
            }
          }
        }

        ul.sub-menu {
          li {
            a {
              color: rgba($white, 0.5);
            }
          }
        }
      }
    }
  }

  &.vertical-collpsed {
    .vertical-menu {
      .sidebar-menu {
        .main-menu-title {
          font-size: 8px;
        }
        > ul {
          > li {
            &:hover > a {
              // background-color: lighten($primary, 2%);
              color: $white;
              i {
                color: $white;
              }
            }
          }
        }

        ul {
          li {
            &.mm-active {
              .active {
                color: $sidebar-menu-item-active-color !important;
              }
            }
          }
        }
      }
    }
  }
}
