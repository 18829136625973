//
// Google font - Roboto
//

@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@600&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Moon+Dance&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

@font-face {
  font-family: "Vastago Grotesk";
  src:
    url("../../../fonts/VastagoGrotesk/font.woff2") format("woff2"),
    url("../../../fonts/VastagoGrotesk/font.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
