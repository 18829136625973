body {
  font-family: "Vastago Grotesk", "sans-serif" !important;
}
.card-container {
  font-family: "Vastago Grotesk", "sans-serif" !important;

  border-radius: 20px !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1) !important;
  width: 100% !important;
  overflow: hidden !important;
  .setup-row {
    padding: 20px;
    display: flex;
    flex-wrap: nowrap;

    .left-section {
      flex: 0 0 33%;
      padding: 20px;
      box-sizing: border-box;
      .heading {
        font-weight: bold;
        font-size: 24px;
        color: #000;
        text-align: left;
        margin-bottom: 5px;
      }
      svg {
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }

    .right-section {
      flex: 1;
      padding: 20px;
      box-sizing: border-box;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .checklist-title {
          flex: 1;
          text-align: left;
          margin-left: 10px;
          font-weight: bold;
          color: #000;
        }
      }

      .divider {
        width: 100%;
        height: 1px;
        background-color: #d2cfd4;
        margin-bottom: 10px;
      }

      .step-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        gap: 15px;
        border-radius: 10px;
        transition: background-color 0.3s;
        cursor: pointer;
        // &:hover {
        //   background-color: #f0e6ff;
        // }

        .step-content {
          display: flex;
          align-items: center;
          flex-grow: 1;
          justify-content: space-between;
          min-width: 0;
          .step-title {
            // white-space: nowrap;
            white-space: normal;
            word-wrap: break-word;
            &.bold {
              font-weight: bold;
            }
          }

          .progress-bar {
            width: 50%;
            display: flex;
            justify-content: flex-end;
          }
        }

        .icon-container {
        }
      }
    }
    @media (max-width: 1200px) {
      .setup-row {
        flex-wrap: wrap;
      }

      .left-section,
      .right-section {
        width: 100%;
      }
    }
    @media (max-width: 768px) {
      .left-section {
        display: none;
      }
      .checklist-title {
        margin-left: 10px;
        font-size: 16px;
      }

      .right-section {
        width: 100%;
      }
    }
    .step-title {
      font-size: 0.9rem;
    }
  }
}

.progress-bar-container {
  width: 50%;
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.small-drawer .left-section {
  display: none;
}
