body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Vastago Grotesk", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.center-screen {
  position: fixed;
  top: 50vh;
  left: 50vw;
}

.in-the-center-and-on-top-of-all {
  position: fixed;
  top: 50vh;
  left: 50vw;
  z-index: 10000;
}

/* UNCOMMENT THE BELOW FOR NEW UI */
.table-header {
  font-size: 18px;
  color: #000000;
  font-family: "Vastago Grotesk";
  font-weight: 400;
}

.table-responsive,
.table {
  color: #000;
  font-family: "Vastago Grotesk";
  font-weight: 500;
  font-size: 18px;
}